import Link from 'next/link';

import { Background } from '../components/background/Background';
import { FooterLinks } from '../components/footer/FooterLinks';
import { FooterTwoRowsCopyright } from '../components/footer/FooterTwoRowsCopyright';
import { Section } from '../components/layout/Section';
import { AppConfig } from '../config/AppConfig';
import { Logo } from './Logo';

// eslint-disable-next-line unused-imports/no-unused-vars
const references = ['Reference', 'Reference', 'Reference', 'Reference'];

const Footer = () => (
  <Background>
    <Section>
      <FooterTwoRowsCopyright siteName={AppConfig.site_name}>
        <div className="flex justify-between">
          <div className="pl-7">
            <Logo />
            <p>
              Digital health platform used to <br /> teach skills to patients
            </p>
            <p className="pt-4">
              548 Market St. <br />
              PMB 31803 <br />
              San Francisco, California 94104
            </p>
          </div>
          {/* eslint-disable-next-line tailwindcss/no-contradicting-classname */}
          <div className="grid grid-cols-2 gap-6 pr-16 sm:grid-cols-3 md:grid-cols-5">
            {/* <FooterLinks title="Product">
              <li>
                <Link href="/">First link</Link>
              </li>
              <li>
                <Link href="/">Second link</Link>
              </li>
              <li>
                <Link href="/">Third link</Link>
              </li>
              <li>
                <Link href="/">Forth link</Link>
              </li>
            </FooterLinks>
            <FooterLinks title="Resources">
              <li>
                <Link href="/">First link</Link>
              </li>
              <li>
                <Link href="/">Second link</Link>
              </li>
              <li>
                <Link href="/">Third link</Link>
              </li>
              <li>
                <Link href="/">Forth link</Link>
              </li>
            </FooterLinks> */}
            <FooterLinks title="Contact">
              <li>
                <Link href="/get-started">Contact Us</Link>
              </li>
            </FooterLinks>
            <FooterLinks title="Legal">
              <li>
                <Link href="https://wellerlabs.com/n/terms-of-use/">Terms of Use</Link>
              </li>
              <li>
                <Link href="https://wellerlabs.com/n/privacy-policy/">Privacy Policy</Link>
              </li>
            </FooterLinks>
            <FooterLinks title="Sites">
              <li>
                <Link href="https://wellerlabs.com">Weller Labs</Link>
              </li>
              <li>
                <Link href="https://weller.care">Weller Care</Link>
              </li>
            </FooterLinks>
          </div>
        </div>
      </FooterTwoRowsCopyright>
    </Section>
  </Background>
);

export { Footer };
